import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Arrow from "../images/svg/arrow.svg";
import { FaCode, FaPen } from "react-icons/fa";
import { MdDevices } from "react-icons/md";

const How = () => {
  return (
    <section className="how-we-help">
      <h2>How we help</h2>
      <div className="how-container">
        <div className="how-service how-responsive">
          <div className="how-icon">
            <MdDevices className="devices" />
          </div>
          <h3>Responsive</h3>
          <p>
            Our mobile-first approach means that your website will look amazing
            across multiple screens.
          </p>
        </div>
        <div className="how-service how-custom">
          <div className="how-icon">
            <FaPen className="pen" />
          </div>
          <h3>Customisable</h3>
          {/* <p>
            We can create a custom CMS to suit your specific needs. This means
            you can edit your content whenever you like!
          </p> */}
          <p>
            Our websites are built with your goals in mind. There isn't a
            'one-size-fits-all' approach here!
          </p>
        </div>
        <div className="how-service how-modern">
          <div className="how-icon">
            <FaCode />
          </div>
          <h3>Modern Technologies</h3>
          <p>
            We build our sites utilising the latest web technologies. Meaning
            your site is always up-to-date and secure.
          </p>
        </div>
        <Link to="/services" className="primary-btn">
          Find out more
        </Link>
        <Link to="/services" className="how-service how-more">
          <div>
            <h3>Find Out More</h3>
            <p>Learn more about our services</p>
            <Arrow className="how-icon" />
          </div>
        </Link>
        <div className="how-img-container">
          <StaticImage
            src="../images/sitting-laptop-large.png"
            alt="Illustration drawing of a person sitting, leaning against a laptop computer"
            className="how-img"
            placeholder="blurred"
          />
        </div>
      </div>
    </section>
  );
};

export default How;
