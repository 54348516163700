import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import Hero from "../components/Hero";
import How from "../components/How";
import Green from "../components/Green";

import SEOImage from "../images/seo-images/home-v2.png";

const Index = () => {
  return (
    <Layout>
      <Helmet>
        <title>Branch Out Online | Website Design & Development</title>
        <meta
          name="description"
          content="We build fast, secure and beautiful websites. Let us help you branch
          out online and grow your business!"
        />
        <meta property="og:image" content={SEOImage} />
      </Helmet>
      <Hero />
      <How />
      <Green />
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        description
        title
      }
    }
  }
`;
