import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Hero = () => {
  return (
    <header className="hero">
      <div className="hero-grid">
        <StaticImage
          src="../images/hero-img-two.png"
          alt="Green blob with cartoon illustration of someone sitting at a laptop"
          className="hero-img"
          placeholder="blurred"
        />
        <div className="hero-content">
          <div className="hero-content-txt">
            <h1>We build beautiful, fast websites!</h1>
            <p>
              Our main goal is to help you branch out online and get the best
              out of your website. Using modern web technologies we are experts
              in building fast, reliable websites to suit your needs and budget.
            </p>
          </div>
          <div className="hero-btns">
            <Link to="/services" className="primary-btn">
              Our services
            </Link>
            <Link to="/contact" className="hero-btn-two">
              Get in touch
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Hero;
