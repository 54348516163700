import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Green = () => {
  return (
    <section className="green-section">
      <div className="green-container">
        <div className="green-container-txt">
          <h2>It's time to branch out online!</h2>
          <p>
            The importance of a beautiful, fast and secure website in today's
            online driven world is a must. Your website is a place to share your
            story, display your products or services and most importantly, sell
            to customers.
          </p>
          <p>
            So what are you waiting for? Get in touch and let us help you branch
            out online!
          </p>
          <Link to="/contact" className="green-section-btn-laptop">
            Start a project
          </Link>
        </div>
        <div className="green-section-images">
          <StaticImage
            src="../images/heatmap-large.png"
            alt="Illustration of a person looking up at laptop screen with code on it"
            className="green-section-img"
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <Link to="/contact" className="green-section-btn">
          Start a project
        </Link>
      </div>
    </section>
  );
};

export default Green;
